<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12" v-if="allowed">
      <b-card no-body class="card-statistics">
        <b-card-header>
          <b-card-title> احصائيات عامة</b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            آحر تحديث: الآن
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <div class="col-12 col-lg-3">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-success">
                    <feather-icon size="24" icon="UsersIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.students_count ? data.students_count : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    اجمالي الطلبة
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
            <div class="col-12 col-lg-3">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-primary">
                    <feather-icon size="24" icon="UsersIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.parents_count ? data.parents_count : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    أولياء الأمور
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
            <div class="col-12 col-lg-3">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-danger">
                    <feather-icon size="24" icon="TruckIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.busses_count ? data.busses_count : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    اجمالي الباصات
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
            <div class="col-12 col-lg-3">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-info">
                    <feather-icon size="24" icon="CompassIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ data.groups_count ? data.groups_count : 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    خطوط السير
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-12">
      <div class="card card-body">
        <h4 class="g">جميع الباصات، اماكنهم وحالاتهم</h4>
        <div v-if="mylat">
          <l-map :zoom="zoom2" :center="[mylat, mylong]">
            <l-tile-layer :url="url" />
            <l-marker
              v-if="bus.location"
              v-for="bus in busses"
              :key="bus._id"
              :lat-lng="[bus.location.lat, bus.location.long]"
            >
              <l-icon>
                <b-img
                  v-if="!bus.status"
                  :src="require('@/assets/images/bus_0.png')"
                  height="50"
                  :title="
                    '[متوقف] ' +
                    bus.title +
                    ' - ' +
                    groupTitle(bus.group_id).title
                  "
                />
                <b-img
                  v-if="bus.status"
                  :src="require('@/assets/images/bus_1.png')"
                  height="50"
                  :title="
                    '[في رحلة الآن] ' +
                    bus.title +
                    ' - ' +
                    groupTitle(bus.group_id).title
                  "
                />
              </l-icon>
            </l-marker>
          </l-map>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="checkPer('archive-view')">
      <h4>
        <img :src="require('@/assets/images/live.gif')" alt="" />
        الرحلات الحالية
        <small class="text-muted"
          ><i class="fa fa-arrows-left-right-to-line"></i> قم بالتمرير
          بالفأرة</small
        >
      </h4>
      <swiper
        class="swiper-centered-slides-2 p-1 row"
        :options="swiperOptions"
        dir="rtl"
      >
        <swiper-slide
          class="rounded swiper-shadow py-1 px-3 d-flex col-12 col-lg-4"
        >
          <div class="swiper-text card card-body text-center">
            <img
              :src="require('@/assets/images/bus.gif')"
              style="width: 80%"
              class="c"
            />
            <h2>
              <span v-if="data.active_trips">
                {{ data.active_trips.length }} رحلة
              </span>
              <span v-if="!data.active_trips"> لا يوجد رحلات. </span>
            </h2>
            <button
              class="btn btn-relief-primary btn-block"
              style="border-radius: 0"
              v-b-modal.modal-3
            >
              <i class="fa fa-list"></i>
              العرض في جدول
            </button>
          </div>
        </swiper-slide>
        <swiper-slide
          class="rounded swiper-shadow py-1 px-3 d-flex col-12 col-lg-4"
          v-for="trip in data.active_trips"
          :key="trip._id"
        >
          <div class="swiper-text card card-body text-center">
            <h1>
              {{ busTitle(trip.bus_id).title }}
            </h1>
            <div class="row text-left">
              <div class="col-12 col-lg-6">
                <p
                  class="badge bg-light-primary btn-block"
                  style="border-radius: 0"
                >
                  اجمالي الطلاب: {{ trip.students ? trip.students : 0 }}
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <p
                  class="badge bg-light-info btn-block"
                  style="border-radius: 0"
                >
                  في الباص:
                  {{
                    trip.statuses["status_1"] ? trip.statuses["status_1"] : 0
                  }}
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <p
                  class="badge bg-light-success btn-block"
                  style="border-radius: 0"
                >
                  نزل:
                  {{
                    trip.statuses["status_2"] ? trip.statuses["status_2"] : 0
                  }}
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <p
                  class="badge bg-light-warning btn-block"
                  style="border-radius: 0"
                >
                  استأذن:
                  {{
                    trip.statuses["status_3"] ? trip.statuses["status_3"] : 0
                  }}
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <p
                  class="badge bg-light-danger btn-block"
                  style="border-radius: 0"
                >
                  مُتبقي:
                  {{
                    trip.statuses["status_0"] ? trip.statuses["status_0"] : 0
                  }}
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <p
                  class="badge bg-light-danger btn-block"
                  style="border-radius: 0"
                >
                  غائب:
                  {{
                    trip.statuses["status_4"] ? trip.statuses["status_4"] : 0
                  }}
                </p>
              </div>
            </div>
            <button
              @click="tripp = trip"
              v-b-modal.modal-2
              class="btn btn-outline-primary"
              style="border-radius: 0"
            >
              <i class="fa fa-map"></i>
              عرض على الخريطة
            </button>
            <br />
            <div class="col-12">
              المشرف: {{ adminTitle(trip.admin_id).name }}
              <br />
              خط السير: {{ groupTitle(trip.group_id).title }} <br />
              {{ trip.date }} &nbsp;&nbsp;
              <a
                class="text-danger"
                @click="endTrip(trip._id)"
                v-if="checkPer('archive-edit')"
                ><i class="fa fa-times"></i> إنهاء الرحلة</a
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="col-12" v-if="checkPer('busses-view')">
      <h4>
        X الباصات المتوقفة (<span v-if="data.stopped_busses">
          {{ data.stopped_busses.length }} باص</span
        >)
        <button
          class="btn btn-outline-primary btn-sm"
          style="border-radius: 0"
          v-b-modal.modal-4
        >
          <i class="fa fa-list"></i>
          عرض كجدول
        </button>
      </h4>
      <swiper
        class="swiper-centered-slides-2 p-1"
        :options="swiperOptions"
        dir="rtl"
      >
        <swiper-slide
          v-for="bus in data.stopped_busses"
          :key="bus._id"
          class="rounded swiper-shadow py-1 px-3 d-flex col-12 col-lg-4"
        >
          <div class="swiper-text card card-body">
            <div>
              <feather-icon icon="TruckIcon" size="18" class="mr-50" />
              {{ bus.title }}
            </div>
            <br />
            خط السير: {{ groupTitle(bus.group_id).title }} <br /><br />
            <button
              @click="
                tripp = {
                  locations: bus.location,
                }
              "
              v-b-modal.modal-2
              class="btn btn-outline-primary"
              style="border-radius: 0"
            >
              <i class="fa fa-map"></i>
              عرض على الخريطة
            </button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-2" title="الخريطة" ok-only ok-title="إغلاق، شكراً لك">
      <div class="col-12">
        <l-map
          v-if="tripp.locations && viewmemap"
          :zoom="zoom"
          :center="[tripp.locations.lat, tripp.locations.long]"
        >
          <l-tile-layer :url="url" />
          <l-marker :lat-lng="[tripp.locations.lat, tripp.locations.long]">
            <l-icon>
              <b-img
                style="padding: 5px"
                :src="require('@/assets/images/bus.png')"
                height="50"
                width="50"
              />
            </l-icon>
          </l-marker>
        </l-map>
      </div>
      <div class="col-12 g text-center">
        <button
          class="btn btn-info"
          @click="viewmemap = true"
          v-if="!viewmemap"
        >
          <i class="fa fa-map"></i>
          فتح الخريطة
        </button>
      </div>
      <div class="col-12 text-center g" v-if="tripp.admin_id && false">
        <button
          class="btn btn-primary"
          @click="$router.push('bus/archive#' + tripp._id)"
        >
          عرض في ارشيف الرحلات
        </button>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-3"
      size="xl"
      title="الرحلات الحالية"
      ok-only
      ok-title="إغلاق، شكراً لك"
    >
      <div class="col-12 table-responsive">
        <table class="table table-hover table-bordered custom-table">
          <thead>
            <th>الباص</th>
            <th>خط السير</th>
            <th>المشرف</th>
            <th>الخريطة</th>
            <th>اجمالي الطلاب</th>
            <th>في الباص</th>
            <th>نزل</th>
            <th>اتسأذن</th>
            <th>مُتبقي</th>
            <th>غائب</th>
            <th>#</th>
          </thead>
          <tbody>
            <tr v-for="trip in data.active_trips" :key="trip._id">
              <td>
                {{ busTitle(trip.bus_id).title }}
              </td>
              <td>
                {{ groupTitle(trip.group_id).title }}
              </td>
              <td>
                {{ adminTitle(trip.admin_id).name }}
              </td>
              <td>
                <button
                  @click="tripp = trip"
                  v-b-modal.modal-2
                  class="btn btn-outline-primary btn-sm"
                  style="border-radius: 0"
                >
                  <i class="fa fa-map"></i>
                  عرض على الخريطة
                </button>
              </td>
              <td>
                {{ trip.students ? trip.students : 0 }}
              </td>
              <td>
                {{ trip.statuses["status_1"] ? trip.statuses["status_1"] : 0 }}
              </td>
              <td>
                {{ trip.statuses["status_2"] ? trip.statuses["status_2"] : 0 }}
              </td>
              <td>
                {{ trip.statuses["status_3"] ? trip.statuses["status_3"] : 0 }}
              </td>
              <td>
                {{ trip.statuses["status_0"] ? trip.statuses["status_0"] : 0 }}
              </td>
              <td>
                {{ trip.statuses["status_4"] ? trip.statuses["status_4"] : 0 }}
              </td>
              <td>
                {{ trip.date }} &nbsp;&nbsp;
                <a
                  class="text-danger"
                  @click="endTrip(trip._id)"
                  v-if="checkPer('archive-edit')"
                  ><i class="fa fa-times"></i> إنهاء الرحلة</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div> </b-modal
    ><!-- basic modal -->
    <b-modal
      id="modal-4"
      size="xl"
      title="الباصات المتوقفة"
      ok-only
      ok-title="إغلاق، شكراً لك"
    >
      <div class="col-12 table-responsive">
        <table class="table table-hover table-bordered custom-table">
          <thead>
            <th>الباص</th>
            <th>خط السير</th>
            <th>الخريطة</th>
          </thead>
          <tbody>
            <tr v-for="bus in data.stopped_busses" :key="bus._id">
              <td>
                {{ bus.title }}
              </td>
              <td>
                {{ groupTitle(bus.group_id).title }}
              </td>
              <td>
                <button
                  @click="
                    tripp = {
                      locations: bus.location,
                    }
                  "
                  v-b-modal.modal-2
                  class="btn btn-outline-primary btn-sm"
                  style="border-radius: 0"
                >
                  <i class="fa fa-map"></i>
                  عرض على الخريطة
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
delete Icon.Default.prototype._getIconUrl;
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import {
  BCard,
  BCardText,
  BLink,
  BOverlay,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BMediaBody,
  VBModal,
} from "bootstrap-vue";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    Swiper,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
    SwiperSlide,
    BCard,
    BCardText,
    BLink,
    BImg,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      zoom: 13,
      zoom2: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      polyline: {
        latlngs: [],
        latlngs2: [],
        color: "green",
        color2: "red",
      },
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      ///////
      mylat: null,
      mylong: null,
      tripp: {},
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      data: {},
      allowed: true,
      busses: [],
      groups: [],
      admins: [],
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 0,
      },
      viewmemap: false,
    };
  },
  created() {
    var g = this;
    $.post(api + "/user/busses/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.busses = r.response;
    });
    $.post(api + "/user/admins/list", {
      jwt: g.user.jwt,
      bus: 1,
    }).then(function (r) {
      g.admins = r.response;
    });
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.groups = r.response;
    });
    g.getStats();
    g.mylat = "24.58028113310656";
    g.mylong = "46.58395573683153";
    setInterval(() => {
      if (!$("#modal-2").is(":visible")) {
        g.viewmemap = false;
      }
    }, 100);
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    getStats() {
      var g = this;
      $.post(api + "/user/general/stats", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          r = r;
          g.data = r.response;
          g.loading = false;
        })
        .catch(function (e) {
          g.allowed = false;
          alert("تعذر الإتصال بالخادم..");
        });
    },
    busTitle(id) {
      var p = {};
      this.busses.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    groupTitle(id) {
      var p = {};
      this.groups.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    adminTitle(id) {
      var p = {};
      this.admins.forEach(function (r) {
        if (r._id == id) {
          p = r;
        }
      });
      return p;
    },
    endTrip(id) {
      var g = this;
      if (confirm("متأكد من انهاء الرحلة؟")) {
        this.loading = true;
        $.post(api + "/user/trips/end", {
          jwt: this.user.jwt,
          id: id,
        }).then(function (r) {
          g.getStats();
        });
      }
    },
  },
};
</script>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}
.badge {
  border-left: 2px solid #0099cc;
}
#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style><style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>